import SectionHead from "./SectionHead"
import {CiMedal} from "react-icons/ci";
import Storyimg from '../images/storyimg.webp'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {storylinePages} from '../data'
import {holdersGet} from '../data'
import AmarokGoal from '../images/amarok-future-image.svg';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={"btn _next"}
        style={{ ...style }}
        onClick={onClick}>
        <span className={className}></span>
      </div>
    );
}

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
          className={"btn _prev"}
          style={{ ...style }}
          onClick={onClick}>
          <span className={className}></span>
        </div>
    );
}

const Storyline = () => {
    var settings = {
        dots: false,
        fade: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    var settings2 = {
        dots: false,
        fade: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    adaptiveHeight: true
                }
            }
        ]
    };

    return (
        <section className="storyline" id="storylineblock">
            <img className="roadmap" src={Storyimg} alt="Storyline Image" />
            <div className="m-scroll">
                <div className="m-scroll__title">
                    <div>
                        <p>
                            <span>AMAROK’S STORY</span>&nbsp;<span>AMAROK’S STORY</span>&nbsp;<span>AMAROK’S STORY</span>&nbsp;<span>AMAROK’S STORY</span>&nbsp;<span>AMAROK’S STORY</span>&nbsp;<span>AMAROK’S STORY</span>&nbsp;
                        </p>
                        <p>
                            <span>AMAROK’S STORY</span>&nbsp;<span>AMAROK’S STORY</span>&nbsp;<span>AMAROK’S STORY</span>&nbsp;<span>AMAROK’S STORY</span>&nbsp;<span>AMAROK’S STORY</span>&nbsp;<span>AMAROK’S STORY</span>&nbsp;
                        </p>
                    </div>
                </div>
            </div>
            <div id="story" className="container storyline__container">
                <Slider {...settings}>
                    {
                        storylinePages.map(({id, picture, pictureWeb, story, name}) => {
                            return <div className="singlePage" key={id}>
                                <div className="leftPart">
                                    <picture>
                                        <source srcSet={pictureWeb} type="image/webp"/>
                                        <source srcSet={picture} type="image/jpeg"/>
                                        <img src={picture} alt={name}/>
                                    </picture>
                                </div>
                                <div className="rightPart">
                                    <p dangerouslySetInnerHTML={{__html: story}}/>
                                    <small>{id} of 3 pages</small>
                                </div>
                            </div>
                        })
                    }
                </Slider>

                <div className="amarokBox" id="goalBox">
                    <div className="boxDesc">
                        <p><em>Amarok</em> is a future <em>Web3</em> browser-based <em>RPG</em> game. For login the <em>NFT</em> cards will be used. The game is in an early stages of development. Locations and opportunities will appear gradually. Everything that happens in the game is influenced by the community. Voting and discussions take place on <em>Discord</em>. In the future, we want to add <em>NFT</em> items separate from the character. <em>90%</em> of investments from <em>NFT</em> sales go to the game, because it is in our interests to create a long-term <em>NFT</em> project.</p>
                    </div>
                    <img src={AmarokGoal} alt="Amarok Wolf #2222" />
                </div>

                <SectionHead icon={<CiMedal/>} title="Holders will get..."/>

                <div className="holderGetSlider" id="holders">
                    <Slider {...settings2}>
                        {
                            holdersGet.map(({id, name, desc}) => {
                                return <div className="singleHolder" key={id}>
                                    <h3>{name}</h3>
                                    <p dangerouslySetInnerHTML={{__html: desc}}/>
                                </div>
                            })
                        }
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Storyline