import './play.css';
import React, { useEffect, useState } from "react";
import Logo from '../../images/amarok_logo.svg';

import {
  useAddress,
  ConnectWallet,
  useContract,
  useNFTBalance,
} from "@thirdweb-dev/react";

import CreateUser from './components/createUser'

const Play = () => {

  let address = useAddress();
  if (address != '') {
    window.sessionStorage.setItem("address", address);
  }

  const contractAddress = "0x2C3838Bf446FBAF6f9cec16fc30963685c3c6C09";
  const ownerAddress = "0xA507876539B7E80F40281a3c5a4Ba95794290EAa";
  const { contract } = useContract(contractAddress, "nft-drop");

  let isConnected;
  address ? isConnected = "_connected" : isConnected = "";

  const isAdmin = address == ownerAddress ? true : false;
  const { data: ownerBalance } = useNFTBalance(contract, address, 4);

  const [data, setData] = useState(null);
  if (data && data != '') {
    window.sessionStorage.setItem("data", data.answerNotExist);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (address) {
        const response = await fetch(`http://localhost:3001/get-user?walletId=${address}`);
        const newData = await response.json();

        if (newData) {
          setData(newData);
        }
      }
    };

    fetchData();
  }, [address]);

  let isSecurelyLoggedIn = false;
  if(data && data.answerExist) {
    isSecurelyLoggedIn = true;
  }

  return (
    <section id="playGame">
        {(() => {
          if (ownerBalance > 0 && isAdmin && data && !data.error) {
            return (
              <div className='playGameContainer'>
                <CreateUser isSecurelyLoggedIn={isSecurelyLoggedIn}/>
              </div>
            )
          } else {
            return (
              <div className="playGameContainer _notConnected">
                <div className="playGameContainerWrap">
                  <a href="/" className='logo'>
                    <img src={Logo} alt="Amarok Logo"/>
                  </a>
                  <h2>Connect to Play</h2>
                  <div className={`btn _walletConnect ${isConnected}`}>
                    <ConnectWallet/>
                  </div>
                </div>
              </div>
            )
          }
        })()}
    </section>
  )
}

export default Play