import {SiOpenaigym} from 'react-icons/si'
import Amarok1Jpg from './images/devil-amarok.jpg'
import Amarok1Web from './images/devil-amarok.webp'
import Amarok2Jpg from './images/andi-amarok.jpg'
import Amarok2Web from './images/andi-amarok.webp'
import Amarok3Jpg from './images/blindy-amarok.jpg'
import Amarok3Web from './images/blindy-amarok.webp'
import StoryImg1Jpg from './images/storyimg1.jpg'
import StoryImg1Web from './images/storyimg1.webp'
import StoryImg2Jpg from './images/storyimg2.jpg'
import StoryImg2Web from './images/storyimg2.webp'
import StoryImg3Jpg from './images/storyimg3.jpg'
import StoryImg3Web from './images/storyimg3.webp'

export const holdersGet = [
    {
        id: 1,
        name: "Unique NFT",
        desc: "A unique hand-drawn <em>NFT</em> card. <em>1</em> out of <em>10,000</em> (the size will increase gradually). Every holder will get additional prizes after beta test of the game."
    },
    {
        id: 2,
        name: "Game access",
        desc: "Full game access from very early stage of development and tests. You will be able to see how the game will be created, changed and become bigger."
    },
    {
        id: 3,
        name: "Votes & debates",
        desc: "Access to a private <em>Discord</em> channel, where debates and votes will take a place. Holders will be fully involved in the creation of the game by voting system."
    },
    {
        id: 4,
        name: "Merch",
        desc: "Upon request, a branded hoodie with the image of your <em>Amarok</em> will be sent to any address. Holders of <em>Legendary</em> and <em>Rare</em> cards will get it for <em>FREE</em>."
    },
    {
        id: 5,
        name: "Real one",
        desc: "There are a lot of speculators and scammers in the <em>Web3</em> industry right now. We are real, so you will get a real <em>Web3</em> experience + big events in real life."
    },
    {
        id: 6,
        name: "Community",
        desc: "Everyone who wants to become part of the project and get into the team can do it. As the game gets bigger and bigger, a bigger team will be needed also."
    },
    {
        id: 7,
        name: "Special tags",
        desc: "First <em>10,000</em> holders will get a special tags to their accounts, so that everyone in the future will see who supported <em>Amarok</em> at the very beginning."
    }
]

export const storylinePages = [
    {
        id: 1,
        picture: StoryImg1Jpg,
        pictureWeb: StoryImg1Web,
        name: "Story begins",
        story: "There is a settlement in the <em>Metaverse</em> called <em>The Wolf 's Village</em>. Only wolves live there. These wolves are friendly and communicate with other members of the <em>Metaverse</em>. But this village is in a big trouble. This trouble was the corporations that, through deceit, taxes and speculation, took almost the last money of the inhabitants of <em>The Wolf 's Village</em>. The inhabitants of this village had already completely given up, but then <em>HE</em> appeared..."
    },
    {
        id: 2,
        picture: StoryImg2Jpg,
        pictureWeb: StoryImg2Web,
        name: "Amarok is here",
        story: "<em>Amarok</em>, <em>The Great Wolf</em>, fed up with the oppression of the corporations. He decided to create his own movement and divided it into 2 factions. Although these factions are different, with different ideas, they are fighting against the same enemy. These inhabitants of the <em>Metaverse</em> understand that only by fighting together for the one idea a powerful enemy can be defeated. This is a real revolution not only for the <em>Wolf 's Village</em>, but for the entire <em>Metaverse</em>."
    },
    {
        id: 3,
        picture: StoryImg3Jpg,
        pictureWeb: StoryImg3Web,
        name: "Great future",
        story: "<em>Amarok</em> along with both factions were able to defeat the evil and the sun returned to <em>The Wolf 's Village</em>. But this is far from the end, but rather only the beginning of a great story. To prevent the evil forces from returning back to <em>The Wolf 's Village</em>, the <em>Amarok 's</em> team will have to keep the balance and protect the <em>The Wolf 's Village</em>, while all other residents can safely enjoy the wonderful life in the <em>Metaverse</em>."
    }
]

export const roadmapSlider = [
    {
        id: 1,
        name: "Q4 2022",
        desc: "First handdrawn version of <em>Amarok</em> created. <em>Twitter</em> account created. Website development in progress. Logic and strategy plan prepared."
    },
    {
        id: 2,
        name: "Q1 2023",
        desc: "Drawing of vector image of <em>Amarok</em> with part of cosmetics and items. Website Web3 features development in progress (connect to a wallet and first interface design)."
    },
    {
        id: 3,
        name: "Q2 2023",
        desc: "First <em>NFT</em> drop will go through our website. One <em>NFT</em> card per account. Only people who follow <em>Twitter</em> will get a link to mint <em>NFT</em>."
    },
    {
        id: 4,
        name: "Q3 2023",
        desc: "Game development will take a place. <em>Discord</em> channel will be created. All holders will be involved to <em>Discord</em> channel to join a development process."
    },
    {
        id: 5,
        name: "Q4 2023",
        desc: "More <em>NFT</em> drops. Holders will be able to see first game screens. Huge debates and votes will take a place in <em>Discord</em> channel."
    },
    {
        id: 6,
        name: "Q1 2024 - RELEASE",
        desc: "First game process will start. Holders will be able to play on first locations. More drops and contests will take a place."
    },
    {
        id: 7,
        name: "FUTURE PLANS",
        desc: "Future vision to be anounced. In short, we want to add <em>NFT</em> wearables so that holders can save them to the character. Also we want ot make game bigger."
    }
]

export const wolfShowcase = [
    {
        id: 1,
        name: "\"Devil\" Amarok",
        number: "2,999",
        status: "Epic (1 in 10)",
        image: Amarok1Jpg,
        imageWeb: Amarok1Web,
        link: "#"
    },
    {
        id: 2,
        name: "\"Andi\" Amarok",
        number: "1",
        status: "Legendary (1 in 1)",
        image: Amarok2Jpg,
        imageWeb: Amarok2Web,
        link: "#"
    },
    {
        id: 3,
        name: "\"Blindy\" Amarok",
        number: "1,344",
        status: "Epic (1 in 10)",
        image: Amarok3Jpg,
        imageWeb: Amarok3Web,
        link: "#"
    }
]

export const projectData = [
    {
        id: 1,
        name: "1ˢᵗ step size",
        value: "10,000"
    },
    {
        id: 2,
        name: "Project size",
        value: "∞"
    },
    {
        id: 3,
        name: "Team size",
        value: "2"
    },
    {
        id: 4,
        name: "Floor",
        value: "0.05",
        small: "eth"
    }
]

export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "About",
        path: '/about'
    },
    {
        name: "Gallery",
        path: '/gallery'
    },
    {
        name: "Plans",
        path: '/plans'
    },
    {
        name: "Trainers",
        path: '/trainers'
    },
    {
        name: "Contact",
        path: '/contact'
    }
]

export const programs = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "Program One",
        info: "This is the day that the lord has made. We will rejoice!",
        path: "/programs/111"
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "Program Two",
        info: "This is the day that the lord has made. We will rejoice!",
        path: "/programs/222"
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "Program Three",
        info: "This is the day that the lord has made. We will rejoice!",
        path: "/programs/333"
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "Program Four",
        info: "This is the day that the lord has made. We will rejoice!",
        path: "/programs/444"
    }
]

export const values = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "Value One",
        desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum."
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "Value Two",
        desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum."
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "Value Three",
        desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum."
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "Value Four",
        desc: "Placeat quidem facere dicta modi? Pariatur exercitationem illum."
    }
]

export const faqs = [
    {
        id: 1,
        question: "How often should I exercise?",
        answer: "Consectetur adipisicing elit. Non ipsa dolorem, rem consequatur eum omnis ex, fuga temporibus qui nesciunt odio aliquam commodi culpa inventore ut similique repellendus perferendis sint!"
    },
    {
        id: 2,
        question: "What time of day is best to work out?",
        answer: "Distinctio nihil blanditiis accusantium atque, quo maxime inventore eum! Cum dolorem quibusdam amet et qui. Eos, omnis beatae? Quas, est at! Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit."
    },
    {
        id: 3,
        question: "How long should my workouts be?",
        answer: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
    },
    {
        id: 4,
        question: "Do I need to warm up before my workouts?",
        answer: "Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit, consequatur quibusdam dignissimos cum labore possimus placeat consectetur nisi cupiditate? Qui totam est omnis dolor nobis quisquam veritatis a!"
    },
    {
        id: 5,
        question: "Should I do strength training, cardio or both?",
        answer: "Maiores fuga, cum praesentium esse laudantium! Distinctio nihil blanditiis accusantium atque, quo maxime inventore eum! Cum dolorem quibusdam amet et qui."
    },
    {
        id: 6,
        question: "Should I lift weights for strength training?",
        answer: "Quas, est at! Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit, consequatur quibusdam dignissimos cum labore possimus placeat consectetur nisi cupiditate."
    }
]


export const testimonials = [
    {
        id: 1,
        name: "Diana Ayi",
        quote: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium ipsam facere ea a laboriosam sed? Quod vel voluptates a! Maxime minima cumque aut? In expedita numquam consectetur non officia iusto.",
        job: "Student",
        avatar: require("./images/avatar1.jpg")
    },
    {
        id: 2,
        name: "Daniel Vinyo",
        quote: "Harum quaerat hic consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum this and that odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
        job: "Software Egineer",
        avatar: require("./images/avatar2.jpg")
    },
    {
        id: 3,
        name: "Edem Quist",
        quote: "Quaerat hic praesentium consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
        job: "University Lecturer",
        avatar: require("./images/avatar3.jpg")
    },
    {
        id: 4,
        name: "Grace Lavoe",
        quote: "Cupiditate deleniti sint consequuntur molestias repellat ad quo tenetur vitae rem, labore quisquam? Atque, assumenda rerum odit harum quaerat hic praesentium quisquam quae, enim iste ipsam id repellat.",
        job: "Talking Parrot",
        avatar: require("./images/avatar4.jpg")
    },
    {
        id: 5,
        name: "Nana Yaa Dankwa",
        quote: "Maxime minima cumque sit amet consectetur adipisicing elit. Praesentium ipsam facere ea a laboriosam sed? Quod vel voluptates a! Maxime minima cumque aut? In expedita numquam consectetur non officia iusto.",
        job: "Pharmacist",
        avatar: require("./images/avatar5.jpg")
    }
]

export const plans = [
    {
        id: 1,
        name: 'Silver Package',
        desc: 'This package is perfect for beginners who need constant help',
        price: 29.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: false},
            {feature: 'Sixth Feature', available: false},
            {feature: 'Seventh Feature', available: false},
            {feature: 'Seventh Feature Plus', available: false},
            {feature: 'Eighth Feature', available: false},
            {feature: 'Ninth Feature', available: false},
            {feature: 'Tenth Feature', available: false},
            {feature: 'Eleventh Feature', available: false}
        ]
    },
    {
        id: 2,
        name: 'Gold Package',
        desc: 'This is the perfect package for beginners who know what their doing',
        price: 49.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: true},
            {feature: 'Sixth Feature', available: true},
            {feature: 'Seventh Feature', available: true},
            {feature: 'Seventh Feature Plus', available: true},
            {feature: 'Eighth Feature', available: false},
            {feature: 'Ninth Feature', available: false},
            {feature: 'Tenth Feature', available: false},
            {feature: 'Eleventh Feature', available: false}
        ]
    },
    {
        id: 3,
        name: 'Platinum Package',
        desc: 'This package is perfect for busy people who need home service',
        price: 89.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: true},
            {feature: 'Sixth Feature', available: true},
            {feature: 'Seventh Feature', available: true},
            {feature: 'Seventh Feature Plus', available: true},
            {feature: 'Eighth Feature', available: true},
            {feature: 'Ninth Feature', available: true},
            {feature: 'Tenth Feature', available: true},
            {feature: 'Eleventh Feature', available: true}
        ]
    }
]

const Trainer1 = require('./images/trainer1.jpg')
const Trainer2 = require('./images/trainer2.jpg')
const Trainer3 = require('./images/trainer3.jpg')
const Trainer4 = require('./images/trainer4.jpg')
const Trainer5 = require('./images/trainer5.jpg')
const Trainer6 = require('./images/trainer6.jpg')

export const trainers = [
    {
        id: 1,
        image: Trainer1,
        name: 'John Doe',
        job: 'Aerobic Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 2,
        image: Trainer2,
        name: 'Daniel vinyo',
        job: 'Speed Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 3,
        image: Trainer3,
        name: 'Edem Quist',
        job: 'Flexibility Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 4,
        image: Trainer4,
        name: 'Shatta Wale',
        job: 'Body Composition Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 5,
        image: Trainer5,
        name: 'Diana Ayi',
        job: 'Circuit Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 6,
        image: Trainer6,
        name: 'Wayne Carter',
        job: 'Physical Intelligence Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    }
]