import React from "react";
import PlayGame from './PlayGame'

import Logo from '../../../images/amarok_logo.svg';
import NightWatchImg from '../images/nightwatch.svg';
import DayWatchImg from '../images/daywatch.svg';

class CreateUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nickname: "",
            faction: 0,
            error: {
                errorText: "",
                errorStatus: true
            },
            isLoggedIn: {
                address: "",
                isLoggedIn: false
            }
        };

        this.handleChange  = this.handleChange.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
        this.handleClick   = this.handleClick.bind(this);
    }

    handleClick(event) {
        this.setState({faction: event.currentTarget.dataset.faction});

        if(event.currentTarget.dataset.faction != 0 && this.state.nickname.length > 3) {
            this.setState({
                error: {
                    errorText: "",
                    errorStatus: false
                }
            });
        }
    }

    handleChange(event) {
        this.setState({
            error: {
                errorText: "",
                errorStatus: false
            }
        });
        let nicknameTest = event.target.value;
        let factionTest  = this.state.faction;

        nicknameTest = nicknameTest.match(/^[A-Za-z0-9_]*[A-Za-z0-9][A-Za-z0-9_]*$/);
        if(!nicknameTest) {
            this.setState({
                error: {
                    errorText: "Only letters, numbers & middle spaces allowed.",
                    errorStatus: true
                }
            });
        }
        if(nicknameTest && nicknameTest[0].length > 10) {
            this.setState({
                error: {
                    errorText: "Maximum 10 symbols allowed.",
                    errorStatus: true
                }
            });
        }
        if(nicknameTest && nicknameTest[0].length < 3) {
            this.setState({
                error: {
                    errorText: "Minimum 3 symbols allowed.",
                    errorStatus: true
                }
            });
        }
        if(factionTest === 0) {
            this.setState({
                error: {
                    errorText: "Please select a faction.",
                    errorStatus: true
                }
            });
        }
        this.setState({nickname: event.target.value});
    }

    handleSubmit(event) {
      let nickname = this.state.nickname;
      let faction = this.state.faction;

      if (nickname.length > 0) {
        const requestOptionsUser = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ address: window.sessionStorage.getItem("address"), username: nickname, faction: faction})
        };
        fetch('http://localhost:3001/create-user', requestOptionsUser)
            .then((res) => res.json())
            .then((post) => {
                if(post.error && post.error.length > 0) {
                    this.setState({
                        error: {
                            errorText: post.error,
                            errorStatus: true
                        }
                    });
                }
                if(post.user && post.user.address && post.user.address === window.sessionStorage.getItem("address")) {
                    this.setState({
                        isLoggedIn: {
                            address: window.sessionStorage.getItem("address"),
                            isLoggedIn: true
                        }
                    });
                }
            })
            .catch((err) => {
                //console.log(err);
            });
        }
        event.preventDefault();
    }

    render() {
        if (this.props.isSecurelyLoggedIn || this.state.isLoggedIn.isLoggedIn) {
            return <PlayGame/>
        }
        if(!this.state.isLoggedIn.isLoggedIn || !this.props.isSecurelyLoggedIn) {
            return (
                <div className="playGameContainerWrap">
                    <form onSubmit={this.handleSubmit} className="factionSelect">
                        <a href="/" className='logo'>
                            <img src={Logo} alt="Amarok logo"/>
                        </a>
            
                        <h2>Select a faction:</h2>
                        <div className={`selectAFaction faction_${this.state.faction}`}>
                            <div className="faction _nightWatch" data-faction="1" onClick={this.handleClick}>
                                <h3>The Night Watch</h3>
                                <div className="imageBlock">
                                    <img className="charImg" src={window.sessionStorage.getItem("data")} alt="Night Watch Character"/>
                                    <img className="factionSymbol" src={NightWatchImg} alt="Night Watch Symbol"/>
                                </div>
                            </div>
            
                            <span>or</span>
            
                            <div className="faction _dayWatch" data-faction="2" onClick={this.handleClick}>
                                <h3>The Day Watch</h3>
                                <div className="imageBlock">
                                    <img className="charImg" src={window.sessionStorage.getItem("data")}/>
                                    <img className="factionSymbol" src={DayWatchImg} alt="Day Watch Symbol"/>
                                </div>
                            </div>
                        </div>
            
                        <div className="formFooter">
                            <p className="createError">{this.state.error.errorText}</p>
            
                            <input className="inp" nickname={this.state.value} onChange={this.handleChange} placeholder="Your Nickname"/>
            
                            <button disabled={this.state.error.errorStatus ? 'disabled' : ''} type="submit" className="btn"><span>Play</span></button>
                        </div>
                    </form>
                </div>
            );
        }
    }
}

export default CreateUser