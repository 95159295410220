import SectionHead from "./SectionHead"
import {RiRoadMapLine} from 'react-icons/ri'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import {roadmapSlider} from '../data'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={"btn _next"}
        style={{ ...style }}
        onClick={onClick}>
        <span className={className}></span>
      </div>
    );
}

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
          className={"btn _prev"}
          style={{ ...style }}
          onClick={onClick}>
          <span className={className}></span>
        </div>
    );
}

const Roadmap = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    adaptiveHeight: true
                }
            }
        ]
    };

    return (
        <section className="roadmapsec" id="roadmap">
            <div className="container roadmapsec__container">
                <SectionHead icon={<RiRoadMapLine/>} title="Amarok’s Roadmap"/>
                <Slider {...settings}>
                    {
                        roadmapSlider.map(({id, name, desc}) => {
                            return <div className="singleRoad" key={id}>
                                <div className="innerBox">
                                    <h3>{name}</h3>
                                    <p dangerouslySetInnerHTML={{__html: desc}}/>
                                </div>
                            </div>
                        })
                    }
                </Slider>
            </div>
        </section>
    )
}

export default Roadmap