// import {Link} from 'react-router-dom'
import { Link, animateScroll as scroll } from "react-scroll"
import {wolfShowcase} from '../data'
import RoadmapWeb from '../images/roadmapimg.webp'
import RoadmapPng from '../images/roadmapimg.png'

import React from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

const CardPreview = () => {
  const { useRef, useEffect } = React;
  const imageMoveRef = useRef(null);

  useEffect(() => {
      gsap.to(".cardpreview .roadmap", {
      y: 450,
      x: -150,
      width: "25rem",
      rotate: 0,
      duration: 10,
      ease: "linear",
      scrollTrigger: {
          trigger: ".cardpreview",
          start: "bottom bottom",
          end: "bottom center",
          scrub: true
      }
      });
  }, []);

  return (
    <section className="cardpreview">
        <div className="container cardpreview__container">

            <picture>
                <source srcSet={RoadmapWeb} type="image/webp"/>
                <source srcSet={RoadmapPng} type="image/png"/>
                <img className="roadmap" ref={imageMoveRef} src={RoadmapPng} alt="Amarok Roadmap"/>
            </picture>

            <h2>The Wolf 's Village</h2>
            <p><em>Web3</em> based RPG browser game with <em>NFT</em> characters and items. Stay tuned!</p>
            <Link to="roadmap" spy={true} smooth={true} offset={-125} duration={500} className="btn mainButton"><span>Roadmap</span></Link>

            <div className="characterBox">
                {
                    wolfShowcase.map(({id, name, number, status, image, imageWeb, link}) => {
                        return <Link className="singleAmarok" key={id} to={link}>
                            <div className="outerBox">
                                <picture>
                                    <source srcSet={imageWeb} type="image/webp"/>
                                    <source srcSet={image} type="image/jpeg"/>
                                    <img src={image} alt={name}/>
                                </picture>

                              <div className="innerInfo">
                                <h3>{name}</h3>
                                <small>#{number}</small>
                                <small>{status}</small>
                              </div>
                            </div>
                        </Link>
                    })
                }
            </div>
        </div>
    </section>
  )
}

export default CardPreview

