import { Link, animateScroll as scroll } from "react-scroll"
import {RiTwitterLine} from 'react-icons/ri'
import {TbBrandDiscord} from "react-icons/tb";

import AmarokFooter from '../images/amarok-footer-image.svg';

const Footer = () => {
  return (
    <footer>
        <div className="container footer__container">
          <div className="btn__group _top">
            <Link to="aboutus" spy={true} smooth={true} offset={-115} duration={1000} className="btn market"><span>About</span></Link>
            <Link to="roadmap" spy={true} smooth={true} offset={-115} duration={1000} className="btn market"><span>Roadmap</span></Link>
            <Link to="story" spy={true} smooth={true} offset={-150} duration={1000} className="btn market"><span>Story</span></Link>
            <Link to="holders" spy={true} smooth={true} offset={-250} duration={1000} className="btn market"><span>Holders</span></Link>
            <Link to="vision" spy={true} smooth={true} offset={-115} duration={1000} className="btn market"><span>Vision</span></Link>
          </div>
          <div className="btn__group">
            <Link to="/pllans" className="btn --hidden"><span>Amarok's Market</span></Link>
            <a href="https://twitter.com/amaroknft" target="_blank" className="btn twitter"><span><RiTwitterLine/></span></a>
            <a href="https://twitter.com/amaroknft" target="_blank" className="btn discord --hidden"><span><TbBrandDiscord/></span></a>
            <span>More options will be announced soon...</span>
          </div>
          <small className="copyright">{new Date().getFullYear()} Amarok &copy; All Rights Reserved</small>
        </div>

        <img className="amarokfooter" src={AmarokFooter} alt="Amarok Footer" />
    </footer>
  )
}

export default Footer