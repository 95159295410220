import MainHeader from '../../components/MainHeader'
import About from '../../components/About'
import Storyline from '../../components/Storyline'
import CardPreview from '../../components/CardPreview'
import Roadmap from '../../components/Roadmap'
import Mint from '../../components/Mint'
import Footer from '../../components/Footer'
import Futurevision from '../../components/Futurevision'
import Navbar from '../../components/Navbar'

const Home = () => {
  return (
    <>
    <Navbar/>
    <MainHeader/>
    {/* <Mint/> */}
    <About/>
    <CardPreview/>
    <Roadmap/>
    <Storyline/>
    <Futurevision/>
    <Footer/>
    </>
  )
}

export default Home