import Amarok from '../images/amarok-about-image.svg'
import BrushPng from '../images/brush.png'
import BrushWeb from '../images/brush.webp'
import {projectData} from '../data'

import React from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

const About = () => {
    const { useRef, useEffect } = React;
    const imageMoveRef = useRef(null);

    useEffect(() => {
        gsap.to("#aboutus .brush", {
        y: 200,
        x: -20,
        rotate: -96,
        duration: 10,
        ease: "linear",
        scrollTrigger: {
            trigger: "#aboutus .amarokBox",
            start: "top top",
            end: "bottom top",
            scrub: true
        }
        });
    }, []);

    return (
        <section className="aboutus" id="aboutus">
            <div className="container aboutus__container">
                <div className="amarokBox">
                    <img src={Amarok} alt="Amarok Wolf #2222" />
                    <div className="boxDesc">
                        <p><em>Amarok</em> is the beginning of a big and interesting <em>Web3</em> game adventure for all of us. This is a unique opportunity for <em>Amarok</em> creators to combine their creativity and freehand drawings with the latest and most modern web technologies to create <em>Web3</em> browser game with <em>NFT</em> characters and <em>NFT</em> items. For token holders, this is a new opportunity to get acquainted with the <em>Web3</em> game world. This is a completely community driven project with no third party investment.</p>
                    </div>

                    <picture>
                        <source srcSet={BrushWeb} type="image/webp"/>
                        <source srcSet={BrushPng} type="image/png"/>
                        <img className="brush" ref={imageMoveRef} src={BrushPng} alt="Amarok Brush"/>
                    </picture>
                </div>

                <p className="bigText"><em>Amarok</em> needs you! Be a part of the new community driven <em>Web3</em> game project and influence the fate of <em>The Wolf ’s Village</em>!</p>

                <div className="projectInfo">
                    {
                        projectData.map(({id, name, value, small}) => {
                            if(small) {
                                small = <small>{small}</small>
                            }
                            return <div key={id}>
                                <div className="inlineBox">
                                    <p>{value}{small}</p>
                                </div>
                                <span>{name}</span>
                            </div>
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default About