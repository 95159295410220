//import {Link} from 'react-router-dom'
import { Link, animateScroll as scroll } from "react-scroll"
import HeaderRouporPng from '../images/header_roupor.png'
import HeaderRouporWeb from '../images/header_roupor.webp'
import WolfImage from '../images/amarok-header-image.svg'
import {RiTwitterLine} from 'react-icons/ri'
import {TbBrandDiscord} from "react-icons/tb"

import React from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

const MainHeader = () => {
  const { useRef, useEffect } = React;
  const imageMoveRef = useRef(null);

  useEffect(() => {
    gsap.to("#main__header .floating", {
      y: 400,
      x: -100,
      rotate: 56,
      duration: 10,
      ease: "linear",
      scrollTrigger: {
        trigger: "#main__header",
        start: "150px top",
        end: "bottom top",
        scrub: true
      }
    });
  }, []);

  return (
    <header className="main__header" id="main__header">
      <img className="main__header-wolf" ref={imageMoveRef} src={WolfImage} alt="Amarok Wolf"/>
      <div className="container main__header-container">

        <picture>
          <source srcSet={HeaderRouporWeb} type="image/webp"/>
          <source srcSet={HeaderRouporPng} type="image/png"/>
          <img className="floating" src={HeaderRouporPng} alt="Amarok Speaker"/>
        </picture>

        <h1><span>Amarok <small>The Great Wolf</small></span></h1>
        <p className="description"><em>Amarok</em> is a collection of <em>10,000</em> NFTs (non-fungible tokens) which are made from hundreds of different hand-drawn particles. This <em>NFT</em> collection consists of an original <em>Amarok</em> character, dressed in various hats, costumes and glasses. Also each <em>NFT</em> has an unique colorful background that immediately catches the eye.</p>

        <div className="btn__group">
          <Link to="aboutus" spy={true} smooth={true} offset={-115} duration={1000} className="btn market"><span>About Amarok</span></Link>

          <div className="socialbutton">
            <a href="https://twitter.com/amaroknft" target="_blank" className="btn twitter"><span><RiTwitterLine/></span></a>
            <a href="https://twitter.com/amaroknft" target="_blank" className="btn discord --hidden"><span><TbBrandDiscord/></span></a>
          </div>
        </div>
      </div>
    </header>
  )
}

export default MainHeader