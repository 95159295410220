import { useState } from 'react';
import { Link, animateScroll as scroll } from "react-scroll"
import Logo from '../images/amarok_logo.svg';
import {RiTwitterLine} from 'react-icons/ri'
import {links} from '../data'
import {HiBars3} from 'react-icons/hi2'
import {IoClose} from 'react-icons/io5'

import {
    useAddress,
    ConnectWallet,
    useNFTBalance,
    useUser,
    useLogin,
    useContract,
    useLogout,
    useMetamask,
} from "@thirdweb-dev/react";

// const sdk = useSDK();
// const balance = sdk.wallet.balance();

const Navbar = () => {
    const [isNavShowing, setIsNavShowing] = useState(false);

    const address = useAddress();
    const connect = useMetamask();

    const login = useLogin();
    const logout = useLogout();
    const user = useUser();

    var isConnected;
    address ? isConnected = "_connected" : isConnected = "";

    const contractAddress = "0x2C3838Bf446FBAF6f9cec16fc30963685c3c6C09";
    const { contract } = useContract(contractAddress, "nft-drop");
    const { data: ownerBalance } = useNFTBalance(contract, address);

    return (
        <nav>
            <div className="container nav__container">
                <div className="headPart">
                    {(() => {
                        // if (isConnected && ownerBalance == 1) {
                        //     return (
                        //         <a href="/play" className="btn"><span>Play! 🐺</span></a>
                        //     )
                        // } else {
                            return (
                                <Link to="mint" spy={true} smooth={true} offset={-150} duration={1000} className="btn"><span>Mint</span></Link>
                            )
                        //}
                    })()}
                </div>

                <Link to="/" className='logo' onClick={() => setIsNavShowing(false)}>
                    <img src={Logo} alt="Nav logo"/>
                </Link>

                <div className="headPart">
                    <div className={`btn _walletConnect ${isConnected}`}>
                        <ConnectWallet/>
                    </div>


                    {/* {address ? (
                        <>
                            <span>User: {JSON.stringify(user || null)}</span>
                        </>
                    ) : (
                        <span></span>
                    )} */}

                    {/* {address ? (
                        <>
                        <button className="btn _walletConnect" onClick={() => login()}>Connect</button>
                        <button className="btn _walletConnect" onClick={logout}>Logout</button>
                        <pre>User: {JSON.stringify(user || null)}</pre>
                        </>
                    ) : (
                        <button className="btn _walletConnect" onClick={connect}>Connect</button>
                    )} */}
                </div>
            </div>
        </nav>

            // <nav>
        //     <div className="container nav__container">
        //         <Link to="/" className='logo' onClick={() => setIsNavShowing(false)}>
        //             <img src={Logo} alt="Nav logo"/>
        //         </Link>
        //         <ul className={`nav__links ${isNavShowing ? 'show__nav' : 'hide__nav'}`}>
        //             {
        //                 links.map(({name, path}, index) => {
        //                     return (
        //                         <li key={index}>
        //                             <NavLink to={path} className={({isActive}) => isActive ? 'active-nav' : ''} onClick={() => setIsNavShowing(prev => !prev)}>{name}</NavLink>
        //                         </li>
        //                     )
        //                 })
        //             }
        //         </ul>
        //         <button className="nav__toggle-btn" onClick={() => setIsNavShowing(prev => !prev)}>
        //             {
        //                 isNavShowing ? <IoClose/> : <HiBars3/>
        //             }
        //         </button>
        //     </div>
        // </nav>
    )

}

export default Navbar