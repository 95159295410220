import Logo from '../../../../images/amarok_logo.svg';
import CoinsIcon from '../../images/amarok-coins.svg';
import CrystalIcon from '../../images/amarok-crystals.svg';
import HealthIcon from '../../images/amarok-health.svg';
import EnergyIcon from '../../images/amarok-energy.svg';

import React, { useEffect, useState } from "react";

const TopCurrency = () => {
    const address = window.sessionStorage.getItem("address");
    const [userFaction, setData] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (address && !userFaction) {
                const response = await fetch(`http://localhost:3001/get-faction?walletId=${address}`);
                const newData = await response.json();

                if (newData && newData.faction) {
                    setData(newData.faction);
                }
            }
        };

        fetchData();
    }, [userFaction]);

    useEffect(() => {
        const fetchData = async () => {
            if (address) {
                const response = await fetch(`http://localhost:3001/get-user-data?walletId=${address}`);
                const newData = await response.json();

                if (newData) {
                    setUserData({
                        coins: newData.coins,
                        crystals: newData.crystals,
                        health: newData.health,
                        energy: newData.energy
                    });
                }
            }
        };

        fetchData();
    }, [userData]);

    if (address) {
        return (
            <div id="TopCharacterBar">
                <img className="logo" src={Logo} alt="Amarok logo"/>
                <div className="characterBar">
                    <div className="characterData _coins">
                        <img src={CoinsIcon} alt="Coins"/>

                        <div className="dataInner">
                            <label>Coins:</label>
                            <p>{userData?.coins}</p>
                        </div>
                    </div>

                    <div className="characterData _crystals">
                        <img src={CrystalIcon} alt="Crystals"/>

                        <div className="dataInner">
                            <label>Crystals:</label>
                            <p>{userData?.crystals}</p>
                        </div>
                    </div>

                    <div className="characterData _health">
                        <img src={HealthIcon} alt="Health"/>

                        <div className="dataInner">
                            <label>Health:</label>
                            <p>{userData?.health} / 100</p>
                        </div>
                    </div>

                    <div className="characterData _energy">
                        <img src={EnergyIcon} alt="Energy"/>

                        <div className="dataInner">
                            <label>Energy:</label>
                            <p>{userData?.energy}</p>
                        </div>
                    </div>
                </div>
                <div className={`faction factionN${userFaction}`}></div>
            </div>
        )
    }
}

export default TopCurrency