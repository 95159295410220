import TopCurrency from './interface/TopCurrency'
import MainInterface from './interface/MainInterface'

const PlayGame = () => {
    return (
        <div id="GameInit">
            <>
            <TopCurrency/>
            <MainInterface/>
            </>
        </div>
    )
}

export default PlayGame