import AmarokVision from '../images/amarok-development-image.svg'
import AmarokVisionBorderJpg from '../images/futurevisionborder.jpg'
import AmarokVisionBorderWeb from '../images/futurevisionborder.webp'
import SectionHead from "./SectionHead"
import {IoRocketOutline} from "react-icons/io5";

const Futurevision = () => {
  return (
    <section className="futurevision" id="vision">
        <div className="container futurevision__container">
            <img className="amarokimg" src={AmarokVision} alt="Amarok Vision" />

            <div className="visionbox">
                <SectionHead icon={<IoRocketOutline/>} title="Future Vision"/>
                <p>In the future, we will add <em>NFT</em> items to the game. Most likely, these items will drop from monsters and they will have special abilities. Also, these items can be sold to other players through the in-game market to get in-game currency or <em>Ethereum</em>. Thus the game will become bigger and bigger, creating its own economy and community. We think it's very interesting, because <em>NFT</em> holders themselves will influence the innovations, updates and the economy in the game!</p>
            </div>

            <picture className="border">
                <source srcSet={AmarokVisionBorderWeb} type="image/webp"/>
                <source srcSet={AmarokVisionBorderJpg} type="image/jpeg"/>
                <img className="border" src={AmarokVisionBorderJpg} alt="Amarok border"/>
            </picture>
        </div>

        <div className="m-scroll">
                <div className="m-scroll__title">
                    <div>
                        <p>
                            <span>DEVELOPMENT STARTED 🐺</span>&nbsp;<span>DEVELOPMENT STARTED 🐺</span>&nbsp;<span>DEVELOPMENT STARTED 🐺</span>&nbsp;<span>DEVELOPMENT STARTED 🐺</span>&nbsp;<span>DEVELOPMENT STARTED 🐺</span>&nbsp;<span>DEVELOPMENT STARTED 🐺</span>&nbsp;
                        </p>
                        <p>
                            <span>DEVELOPMENT STARTED 🐺</span>&nbsp;<span>DEVELOPMENT STARTED 🐺</span>&nbsp;<span>DEVELOPMENT STARTED 🐺</span>&nbsp;<span>DEVELOPMENT STARTED 🐺</span>&nbsp;<span>DEVELOPMENT STARTED 🐺</span>&nbsp;<span>DEVELOPMENT STARTED 🐺</span>&nbsp;
                        </p>
                    </div>
                </div>
            </div>
    </section>
  )
}

export default Futurevision